@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71% 4%;
        --foreground: 213 31% 91%;

        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;

        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;

        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;

        --border: 216 34% 17%;
        --input: 216 34% 17%;

        --card: 224 71% 4%;
        --card-foreground: 213 31% 91%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;

        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;

        --destructive: 0 63% 31%;
        --destructive-foreground: 210 40% 98%;

        --ring: 216 34% 17%;

        --radius: 0.5rem;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
        font-feature-settings: "rlig" 1, "calt" 1;
    }
}





body { background-color: #f5f5f5; padding: 0; margin: 0; font-family: 'Inter', sans-serif; }

.Panels { display: flex; }

h3, h4 { font-size: 15px; font-weight: bold; }
h3 { margin-bottom: 10px; position: relative; }
h3::after { content: ''; height: 1px; background: lightgray; width: 100vw; position: absolute; left: 50%; transform: translateX(-50%); }

p, h2, h3, h4 { position: relative; }
input { border: 1px solid lightgrey; border-radius: 10px; padding: 5px; outline: none; }
textarea { resize: none; }

svg { pointer-events: none; } /* We don't want to receive directly on SVGs. Their parents i.e. buttons will handle it. */


.btn { position: relative; height: 30px; padding: 0 12px; font-size: 14px; box-shadow: none !important; }
.btn.active:focus { box-shadow: none; }
.btn:active:focus { box-shadow: none; }
.form-control:focus { box-shadow: none; }



.container { width: 100%; margin: 0; max-width: none; padding: 0; height: calc(100vh - 56px); position: relative; }

.top-navbar { background-color: #f5f5f5; height: 60px; border-bottom: 1px solid lightgrey; position: relative; display: flex; align-content: center; align-items: center; }
.company-name { font-size: 30px; font-weight: bold; height: 40px; margin: 0 20px; display: inline-block; }
.logo { height: 50px; width: 50px; display: inline-block; margin-left: 25px; }
/*.logo img { height: 100%; width: 100%; }*/

.login-panel-container { height: 100vh; width: 100vw; display: flex; justify-content: center; align-items: center; background: #0a0a0a; }
.login-panel { display: flex; flex-direction: column; justify-content: center; align-items: center; width: 220px; top: 50%; left: 50%; transform: translate(-50%, -50%); position: absolute; }
.logo { width: 150px; height: 150px; margin-bottom: 20px; }

.g-id-sign-in { background: white; }

.references-panel-container, .script-panel-container, .storyboard-panel-container { height: 100%; transition: all 0.5s; overflow-y: hidden; }
.references-panel-container { width: 23%; }
.script-panel-container { width: 54%; }
.storyboard-panel-container { width: 23%; }
.references-panel-content, .script-panel-content, .storyboards-panel-content { height: calc(100vh - 38px); overflow-y: auto; overflow-x: hidden; }

.dropzone-references, .dropzone-references-container { position: absolute; top: 0; height: 100%; width: 100%; }


.reference-notes-container, .storyboard-notes-container { position: absolute; margin: 5px; bottom: 0; left: 0; width: calc(100% - 10px); max-height: 40%; height: fit-content; background: white; overflow-y: auto; opacity: 0; visibility: hidden; border-radius: 10px; font-size: 12px; transition: all 0.2s; }
.reference-component:hover .reference-notes-container, .storyboard-component:hover .storyboard-notes-container { opacity: 0.9; visibility: visible; }
.reference-notes-textarea, .storyboard-notes-textarea { height: 100%; width: 100%; resize: none; outline: none; border: none; padding: 5px; }


.panel-title-container { position: absolute; display: flex; align-items: center; justify-content: space-between; height: 30px; z-index: 20; background: white; border-right: 1px solid lightgrey; border-bottom: 1px solid lightgrey; border-bottom-right-radius: 10px; font-weight: 500; }
.hide-panel-button { position: absolute; display: flex; top: 50%; transform: translateY(-50%); right: 10px; cursor: pointer; height: 20px; width: 20px; }

.add-reference-button, .add-storyboard-button { position: absolute; bottom: 15px; right: 15px; width: 40px; height: 40px; border-radius: 50%; padding: 0; z-index: 30; }

.image-generator-editor-toolbar { display: flex; gap: 10px; }

.canvas-and-toolbar-container { display: flex; height: 100%; }
.drawing-canvas-toolbar, .generated-image-viewer-canvas-toolbar { display: flex; flex-direction: column; padding: 0; gap: 10px; }
.drawing-canvas-toolbar-item { height: 70px; width: 70px; border: 1px solid lightgrey; padding: 6px; border-radius: 10px; user-select: none; display: flex; flex-direction: column; justify-content: center; align-items: center; transition: all 0.5s; }
.drawing-canvas-toolbar-item:hover { border-color: #121212; }
.drawing-canvas-toolbar-item svg { height: 50%; width: 50%; }
.drawing-canvas-toolbar-item.active { border-color: goldenrod; }
.drawing-canvas-toolbar-item.active .drawing-canvas-toolbar-item-label { color: #121212; }
.drawing-canvas-toolbar-item-label { font-size: 12px; text-align: center; color: darkgrey; margin-top: 4px; }
.image-generator-dialog { height: 760px; }
.prompt-text-area { width: 580px; margin-left: 78px; }

.line-art-grid { display: flex; flex-wrap: wrap; gap: 10px; overflow-y: auto; height: 425px; margin-top: 10px; user-select: none; }
.line-art-grid img { width: 100%; height: 100%; border-radius: 10px; object-fit: contain; }
.line-art-item { width: 146px; height: 146px; border: 1px solid lightgrey; border-radius: 10px; position: relative; cursor: pointer; display: flex; justify-content: center; align-items: center; padding: 15px; }

.line-art-gallery { width: 100%; margin-left: 10px; }


.script-toolbar-container { background: #f1f5f9; border-radius: 20px; padding: 0 15px; height: 45px; align-items: center; width: fit-content; display: flex; justify-content: center; z-index: 1; position: absolute; top: 80px; left: 50%; transform: translateX(-50%); user-select: none; }
.script-toolbar-button { display: flex; transition: all 0.2s; height: 100%; border-radius: 0; }
.script-toolbar-button:hover { background: #cbd5e1 !important; }
.script-toolbar-button-icon { margin-right: 8px; }

.script-panel-container { border-top: 1px solid lightgrey; padding: 0; }
.script-panel-content { font-family: 'Roboto Mono', monospace; font-size: 13px; padding: 150px 15px 15px 15px; position: relative; background: white; }

.dialogue { position: relative; padding: 0 0 0 100px; line-height: 1.7; }
.dialogue:hover::after { background: #fff1d4; border-radius: 10px; content: ''; position: absolute; width: calc(100% + 20px); height: calc(100% + 20px); padding: 10px; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: -1; }
.dialogue:hover .add-audio-button { display: block; }

.prompt-input-container { position: absolute; top: -50px; left: 50%; transform: translateX(-50%); border-radius: 15px; padding: 5px; background: white; display: none; width: 300px; }
.open-image-editor-button { width: 49%; height: 40px; }
.open-image-editor-icon-holder { width: 50px; height: 50px; margin-top: 3px; }
.submit-storyboard-prompt-button { width: 49%; height: 40px; }
.submit-icon-holder { width: 30px; height: 30px; margin-top: 3px; }
.add-storyboard-input { margin-bottom: 6px; }

.upper-canvas { border: 1px solid lightgrey; }
.editor-options { padding: 15px 20px; position: relative; }
.clear-canvas-button { position: absolute; right: 20px; }
.drawing-mode-button.active { background: #055675; color: white; }
.erase-mode-button.active { background: #af4829; color: white; }
.select-mode-button.active { background: #055675; color: white; }
.re-generate-button { margin-left: 20px; }

.add-audio-button .prompt-input-container { top: -2px; left: -135px; width: 250px; border: 1px solid lightgray; padding: 12px; }
/*.text-for-tts { padding: 5px; }*/


.add-audio-button { display: none; /* top and left are set by JS */ left: 0; top: 0; }
.add-audio-button:hover { background: #ffe5b0; }
.add-audio-button svg { height: 100%; width: 100%; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); margin-top: 2.5px; }

.audio-player-container { position: absolute; left: 40px; z-index: 1; width: 25px; height: 25px; border: 1px solid grey; border-radius: 1000px; top: 0; cursor: pointer; }
.audio-player-button { height: 100%; width: 100%; position: absolute; top: 0; left: 0; }
audio.audio-player { visibility: hidden; }
.audio-player-container svg { position: absolute; top: 56%; left: 53%; transform: translate(-50%, -50%); }
.audio-player-container:hover { border-color: red; }
.audio-player-container.playing svg { fill: red; }
.audio-player-container.playing { border-color: red; }

.add-audio-control-unit { display: flex; align-items: center; margin: 12px 0; }
.add-audio-button select { margin-left: 12px; }

.status-indicator { height: 30px; }
.status-indicator.in-progress { color: cadetblue; }
.status-indicator.success { color: forestgreen; }
.status-indicator.error { color: crimson; }


.generate-audio-popup { position: absolute; left: 0; width: 300px; border: 1px solid grey; box-shadow: 4px 3px 12px grey; border-radius: 15px; padding: 15px; background: white; display: none; height: fit-content; flex-direction: column; }
.generate-audio-popup select { margin-left: 20px; }


.references-panel-container, .storyboard-panel-container { padding: 0; border: 1px solid lightgrey; position: relative; }
.references-panel-content, .storyboards-panel-content { position: relative; padding: 0 10px; background: #f8fafc; }
.references-panel-content { --dot-bg: #f8fafc; --dot-color: #94a3b8; --dot-size: 1.5px; --dot-space: 22px;
    background:
            linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
            linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
            var(--dot-color); }

.empty-references-panel, .empty-storyboards-panel { padding: 15px; color: grey; pointer-events: none; font-size: 12px; }

.panel-title { color: #334155; padding: 3px 12px 3px 8px; margin: 0; user-select: none; }

.dummy-element-for-scroll { width: 1px; height: 1px; position: absolute; top: 20000px; }

.generated-image { max-height: 250px; max-width: 250px; background: white; margin: 0; border-radius: 10px; z-index: 0; }
.generated-image:hover { z-index: 1; cursor: grab; }
/*.generated-image:hover img { transform: scale(1.1); }*/
.generated-image img { border-radius: 10px; box-shadow: 3px 3px 15px 0 rgba(0,0,0,0.25); transition: transform 0.2s; pointer-events: none; /* we don't want to catch events directly on this element */}
.generated-image.reference { left: 15px; }
.generated-image.storyboard { width: 300px; height: 186px; max-width: none; }
.generated-image.storyboard img { width: 100%; height: 100%; object-fit: cover; }

.delete-image-button { position: absolute; top: 5px; right: 5px; visibility: hidden; height: 30px; width: 30px; transition: all 0.2s; z-index: 2; border-radius: 3px; padding: 5px; cursor: pointer; opacity: 0.65; }
.edit-image-button { position: absolute; top: 5px; right: 40px; visibility: hidden; height: 30px; width: 30px; transition: all 0.2s; z-index: 2; border-radius: 3px; padding: 5px; cursor: pointer; opacity: 0.65; }
.delete-image-button:hover, .edit-image-button:hover { opacity: 1; }
.generated-image:hover .delete-image-button { visibility: visible; }
.generated-image:hover .edit-image-button { visibility: visible; }
.generated-image:hover .has-tags-indicator { visibility: visible; }


.reference-component-metadata { display: flex; justify-content: space-between; width: 100%; }

.tags-toolbar { display: flex; gap: 10px; }
.tags-container { display: flex; flex-wrap: wrap; gap: 5px; width: 100%; }
.reference-tag { background: lightgrey; padding: 2px 5px; border-radius: 5px; font-size: 12px; display: flex; align-items: center; gap: 5px; cursor: pointer; border: 1px solid lightgrey; transition: all 0.3s; }
.reference-tag:hover { border-color: #121212; }
.reference-tag svg { height: 20px; width: 20px; }
.add-tag-input, .add-tag-button { height: 100%; }
.add-tag-input { margin-left: 50px; }


.has-tags-indicator { position: absolute; top: 5px; left: 5px; background: rgba(15, 23, 42, 0.7); height: 30px; width: 30px; transition: all 0.2s; z-index: 2; border-radius: 3px; padding: 5px; font-size: 12px; display: flex; justify-content: center; align-items: center; pointer-events: none; visibility: hidden; }
.has-tags-indicator svg { height: 20px; width: 20px; fill: white; }

.highlighted-child { background: antiquewhite;}
.highlighted-script-element::after { position: absolute; content: ''; width: calc(100% + 20px); height: calc(100% + 20px); padding: 10px; top: 50%; left: 50%; transform: translate(-50%, -50%); pointer-events: none; background: rgba(255, 210, 98, 0.3); border-radius: 5px; z-index: -1; }

.selected-text { position: relative; }
.selected-text::after { position: absolute; content: ''; height: calc(100% + 2px); width: calc(100% + 2px); top: 50%; left: 50%; transform: translate(-50%, -50%); background: lightyellow; z-index: -1; border-radius: 3px; }

.text-for-reference { position: relative; }
.highlighted-reference-text::after { position: absolute; content: ''; height: calc(100% + 2px); width: calc(100% + 2px); top: 50%; left: 50%; transform: translate(-50%, -50%); background: rgba(255, 210, 98, 0.3); z-index: -1; border-radius: 3px; }

.highlighted-reference-image img { outline: 2px solid rgba(255, 210, 98, 0.8); }

.buttons-container { width: 100%; display: flex; justify-content: space-between; }
.generate-audio-button, .close-popup-button { width: calc(50% - 5px); }

.generated-image-viewer-container { height: 486px; position: absolute; z-index: 4; display: flex; justify-content: center; background: white; }
.canvas-and-toolbar-container, .generated-image-viewer-container { display: flex; gap: 10px; }

.modal-dialog { width: 95%; max-width: none; }
/*.modal-dialog-scrollable .modal-content { height: 100%; }*/
.src-image-container { padding: 20px; display: none; justify-content: center; align-items: center; height: 400px; position: absolute; transition: all 0.5s; z-index: 2; background: white; }
.src-image-container img { height: 100%; width: 100%; object-fit: contain; border: 3px solid white; }
.edit-image-prompt-container { margin-bottom: 95px; padding: 0 20px; }
/*.result-ui.generation-success .src-image-container { width: 200px; height: fit-content; top: 0; left: 0; }*/

.editor-canvas-and-resources-container { display: flex; height: 500px; }
.editor-resources-container { position: relative; width: calc(100% - 800px); height: 100%; padding: 10px; display: flex; flex-direction: column; border-bottom: 1px solid lightgrey; overflow: hidden; }
.editor-resources-title { padding: 5px; text-align: center; font-weight: bold; background: lightgray; border-radius: 5px; display: none; }
.editor-resources-search {  }
.editor-resources-list { height: calc(100% - 34px); overflow-y: auto; overflow-x: hidden; margin-top: 10px; padding-bottom: 10px; display: grid; grid-template-columns: 23% 23% 23% 23%; flex-shrink: 0; flex-wrap: wrap; justify-content: space-between; align-content: baseline; }
.editor-resource { height: 70px; width: 100%; position: relative; cursor: pointer; border: 1px solid grey; border-radius: 5px; display: flex; padding: 7px; flex-shrink: 0; flex-wrap: wrap; flex-direction: column; justify-content: space-between; transition: all 0.5s; margin: 5px 0; }
.editor-resource:hover { border-color: #ffd262; }
.editor-resource-name { font-weight: bold; font-size: 10px; text-align: center; display: none; }
.editor-resource-image-container { height: 100%; width: 100%; }
.editor-resource-image { height: 100%; width: 100%; object-fit: contain; }
#dummy-hidden-canvas { visibility: hidden; pointer-events: none; }


.editorcanvas-resultui-container { position: relative; width: 800px; height: 100%; }
.result-ui { display: none; width: 100%; height: 100%; }
.editorcanvas-resultui-container.generation-success .result-ui { display: grid; grid-template-columns: auto auto; position: absolute; top: 0; }
.generated-result-image-container { position: relative; }
.generated-result-image { width: 100%; height: 100%; }
.close-generated-result-image-container-button { position: absolute; top: 10px; right: 10px; cursor: pointer; background: white; height: fit-content; font-size: 14px; }

.generation-status-indicator-container { width: calc(100% - 1px); height: calc(100% - 1px); background: white; position: absolute; top: 0; pointer-events: none; visibility: hidden; display: flex; justify-content: center; align-items: center; z-index: 5; transition: all 0.5s; }
.generation-in-progress .generation-status-indicator-container { visibility: visible; }

.is-hidden { display: none; }
.vertically-centered { position: absolute; top: 50%; transform: translateY(-50%); }
.centered { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
.template { display: none; }


.reference-component, .storyboard-component { z-index: 10; max-width: 100%; max-height: 100%; position: absolute; }
.dropzone-references-container { z-index: 0; position: absolute; height: calc(100vh - 40px); }

.references-carousel-container.hidden { display: none; }
.references-carousel-backdrop { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); z-index: 40; }
.references-carousel-container { position: absolute; z-index: 35; top: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; }
.references-carousel { width: 750px; height: 90%; display: flex; justify-content: center; align-items: center; z-index: 40; }
.references-carousel > .overflow-hidden { height: 100%; }
.reference-content-carousel { height: 80%; }
.reference-carousel-card-content { height: 100%; flex-direction: column; justify-content: space-between; }
.reference-notes-carousel { padding: 10px; width: 400px; border: 1px solid lightgrey; border-radius: 10px; outline: none; }


.writing-mode-focused .script-panel-container { width: 75%; background: white; border: 1px solid lightgrey; }
.writing-mode-focused .references-panel-container { transform: scale(0.7); filter: grayscale(100%) blur(40px); opacity: 0.3; pointer-events: none; }
.writing-mode-focused .storyboard-panel-container { transform: scale(0.7); filter: grayscale(100%) blur(40px); opacity: 0.3; pointer-events: none; }


.slider-popover { width: 120px; }

.cdx-marker { background: rgba(245,235,111,0.29); padding: 3px 0; }

.cdx-block { outline: none; }

.ce-block { position: relative; }

.ce-dialogue { margin-left: 50px; }

.ce-character { font-weight: 500; text-align: center; text-transform: uppercase; line-height: 2; }

.ce-transition { text-align: right; text-transform: uppercase; }

.ce-toolbar { left: auto; font-family: 'Inter', sans-serif; }
.ce-toolbar__settings-btn { display: none; }

.ce-inline-toolbar { display: none; }

.ce-popover { margin-left: -160px; }
.ce-popover-item__icon svg { height: 18px; width: 18px; }


.hide { display: none; }